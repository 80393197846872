import React from "react";
import PropTypes from "prop-types";
import { Typography, Box } from "@material-ui/core";

import Emoji from '../../helpers/Emoji';

export default function SideDetails(props) {
  const { title, details, emoji } = props;
  return (
      <Box p={2}>
        <Typography variant="h6">
          <Emoji symbol={emoji} label={title} />
          <span>&nbsp;</span>
          <span><b>{title}</b></span>
        </Typography>
        <Typography variant="body1">
          {details}
        </Typography>
      </Box>
  );
}

SideDetails.propTypes = {
  title: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  emoji: PropTypes.string.isRequired
};
