import React, { useState, useEffect } from 'react';
import {
    Input, Typography, Grid, CssBaseline, CardMedia,
    CardContent, CardActions, Card, Button, Box,
    MenuItem, FormControl, Select, InputBase
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import firebase from '../config/Firebase';

import { Link } from 'react-router-dom';

// Import components
import Header from './Header';
import Footer from './Footer';

import Emoji from '../helpers/Emoji';


const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: theme.shadows[2],
        // marginBottom: theme.spacing(4),
        // marginTop: theme.spacing(4),
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    search: {
        margin: theme.spacing(1),
        width: "100%"

    },
    searchCard: {
        boxShadow: theme.shadows[2],
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
    },
    dropdown: {
        width: "100%",
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    input: {
        padding: "4px",
        fontsize: "16px",
        border: "1px solid grey",
        width: "100%",
    },
    inputLabel: {
        marginLeft: theme.spacing(1),
    },
    link: {
        margin: theme.spacing(1, 1.5),
        textDecoration: "none"
    },
}));

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

function Cockpit(props) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Header classes={classes} />
            <main>
                {/* Hero unit */}
                <div className={classes.heroContent}>
                    <Container maxWidth="sm">
                        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                            gobukit.
                  </Typography>
                        <Typography variant="h5" align="center" color="textSecondary" paragraph>
                            Your adventure begins here!
                  </Typography>
                        {/* Search card */}
                        <Card className={classes.searchCard}>
                            <Box p={2}>
                                <Grid container spacing={5}>
                                    <Grid item xs={8}>
                                        <FormControl className={classes.search}>
                                            {/* <InputLabel className={classes.inputLabel}>Search Adventures...</InputLabel> */}
                                            <Input type="text" className={classes.input}
                                                placeholder="Search Adventures..."
                                                onChange={props.handleSearchChange} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl className={classes.dropdown}>
                                            {/* <InputLabel>Type</InputLabel> */}
                                            <Select
                                                value={props.type}
                                                onChange={props.handleDropdownChange}
                                                input={<BootstrapInput />}
                                            >
                                                <MenuItem value={0}>
                                                    <em>All</em>
                                                </MenuItem>
                                                <MenuItem value={1}>Dive</MenuItem>
                                                <MenuItem value={2}>Hike</MenuItem>
                                                <MenuItem value={3}>Others</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                </Grid>
                            </Box>
                        </Card>
                    </Container>
                </div>
            </main>
        </React.Fragment>
    );
}

// function searchString(searchString, search){
//     const strArray = searchString.split(" ");
//     for(let i=0 ; i < strArray.length ; i++){
//        if(strArray[i].indexOf(search) == 0){
//            return true;
//        } 
//     }
//     return false;
// }


// TODO: Optimize the search and see how it can be different
function loopJSON(jsonList, search, type) {
    let results = []

    if (type === 0) {
        if (search.length === 0) {
            return jsonList;

        } else {
            search = search.toLowerCase();
            for (let i = 0; i < jsonList.length; i++) {
                if (jsonList[i].name.toLowerCase().indexOf(search) >= 0) {
                    // if (searchString(jsonList[i].name.toLowerCase(), search)) {
                    results.push(jsonList[i]);
                }
            }
        }

    } else {
        if (search.length === 0) {
            for (let i = 0; i < jsonList.length; i++) {
                if (jsonList[i].type == type) {
                    results.push(jsonList[i]);
                }
            }

        } else {
            search = search.toLowerCase();
            for (let i = 0; i < jsonList.length; i++) {
                if (jsonList[i].name.toLowerCase().indexOf(search) >= 0 && jsonList[i].type == type) {
                    results.push(jsonList[i]);
                }
            }
        }

    }

    return results;
}



export default function Adventures(props) {
    const classes = useStyles();

    // Set default as null so we
    // know if it's still loading
    const [adventuresInfo, setAdventures] = useState(null);

    const [everyAdventure, setAllAdventures] = useState(null);

    const [search, setSearch] = useState("");
    const handleSearchChange = event => {
        if (event.target.value.length === 0) {
            setSearch(event.target.value);
            const results = loopJSON(everyAdventure, event.target.value, type);
            setAdventures(results)
        } else {
            setSearch(event.target.value);
            const results = loopJSON(everyAdventure, event.target.value, type);
            setAdventures(results);
        }

    };

    Date.prototype.getUnixTime = function () { return this.getTime() / 1000 | 0 };
    if (!Date.now) Date.now = function () { return new Date(); }
    Date.time = function () { return Date.now().getUnixTime(); }


    const [type, setType] = useState(0);
    const handleDropdownChange = event => {
        setType(event.target.value);
        const results = loopJSON(everyAdventure, search, event.target.value);
        setAdventures(results);
    };



    // Initialize with listening to our
    // messages collection. The second argument
    // with the empty array makes sure the
    // function only executes once
    useEffect(() => {
        listenForAdventuresInfo();
    }, []);


    // Use firestore to listen for changes within
    // our newly created collection
    const listenForAdventuresInfo = () => {
        const unixTime = new Date().getUnixTime();
        firebase.firestore().collection('adventures').where("unixTime", ">", unixTime).orderBy("unixTime")
            .onSnapshot((snapshot) => {
                // Loop through the snapshot and collect
                // the necessary info we need. Then push
                // it into our array
                const allAdventures = [];
                snapshot.forEach((doc) => allAdventures.push(doc.data()));

                // Set the collected array as our state
                setAdventures(allAdventures);
                setAllAdventures(allAdventures);
            }, (error) => console.error(error));
    };

    if (!adventuresInfo) {
        return (
            <div>
                Loading...
          </div>
        )
    }

    if (!adventuresInfo.length) {
        return (
            <React.Fragment>
                <CssBaseline />
                <Cockpit handleSearchChange={handleSearchChange} handleDropdownChange={handleDropdownChange} type={type} />
                <Footer />
            </React.Fragment>
        );
    }



    return (
        <React.Fragment>
            <CssBaseline />
            <Cockpit handleSearchChange={handleSearchChange} handleDropdownChange={handleDropdownChange} type={type} />
            <main>
                <Container className={classes.cardGrid} maxWidth="md">
                    {/* End hero unit */}
                    <Grid container spacing={4}>
                        {adventuresInfo.map(({ cost, dates, location, name, image, id }, index) => (
                            <Grid item key={index} xs={12} sm={12} md={6}>
                                <Card className={classes.card}>
                                    <CardMedia
                                        className={classes.cardMedia}
                                        image={image}
                                    />
                                    <CardContent className={classes.cardContent}>
                                        <Typography gutterBottom variant="h6" component="h3">
                                            <b>{name}</b>
                                        </Typography>
                                        <Typography>
                                            <Emoji symbol="📍" label="location" />
                                            <span>&nbsp;</span>
                                            {location}
                                        </Typography>
                                        <Typography>
                                            <Emoji symbol="📅" label="dates" />
                                            <span>&nbsp;</span>
                                            {dates}
                                        </Typography>
                                        <Typography>
                                            <Emoji symbol="💰" label="cost" />
                                            <span>&nbsp;</span>
                                            {cost}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Link to={'/adventures/' + id} className={classes.link}>
                                            <Button size="small" color="primary" variant="outlined">
                                                View
                                            </Button>
                                        </Link>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </main>
            {/* Footer */}
            <Footer />
        </React.Fragment>
    );
}