import React, { useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

import { withRouter } from "react-router";

import { DatePicker } from '@material-ui/pickers';

import firebase from '../../config/Firebase';

const types = [
  {
    value: '1',
    label: 'Dive',
  },
  {
    value: '2',
    label: 'Hike',
  },
  {
    value: '3',
    label: 'Others',
  }
];

const useStyles = makeStyles(theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

// export default function VendorForm(props) {
const VendorForm = ({ history }) => {
  const classes = useStyles();
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(null);
  const [selectedDateTo, setSelectedDateTo] = React.useState(null);
  
  Date.prototype.getUnixTime = function() { return this.getTime()/1000|0 };
    if(!Date.now) Date.now = function() { return new Date(); }
    Date.time = function() { return Date.now().getUnixTime(); }
  const handleDateChangeFrom = date => {
      setSelectedDateFrom(date);
  };

  const handleDateChangeTo = date => {
      setSelectedDateTo(date);
    
  };

  const [type, setType] = React.useState('1');

  const handleType = event => {
    setType(event.target.value);
  };

  

  // const [itinerary, setItinerary] = React.useState('');

  // const handleItineraryChange = event => {
  //   setItinerary(event.target.value);
  // };

  const handlePost = useCallback(
    async event => {
      event.preventDefault();
      const { title, location, price, duration, dateFrom, dateTo, image, pickup, dropoff, details, itinerary, included, excluded, operator, type } = event.target.elements;
      const dateParts = dateFrom.value.split("/");
      // month is 0-based, that's why we need dataParts[1] - 1
      const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      const unixTime = dateObject.getUnixTime();
      const db = firebase.firestore();
      db.collection("adventure").add({
        name: title.value,
        price: price.value,
        dates: dateFrom.value + " to " + dateTo.value,
        dropoff: dropoff.value,
        pickup: pickup.value,
        included: included.value,
        excluded: excluded.value,
        details: details.value,
        itinerary: itinerary.value,
        image: image.value,
        link: "gobukit.com/adventures",
        operator: operator.value,
        location: location.value,
        duration: duration.value,
      }).then(function (docRef) {
        console.log("Document written with ID: ", docRef.id);
        db.collection("adventures").add({
          name: title.value,
          cost: price.value,
          dates: dateFrom.value + " to " + dateTo.value,
          id: docRef.id,
          image: image.value,
          link: "gobukit.com/adventures",
          location: location.value,
          type: type.value,
          unixTime: unixTime
        })
          .catch(function (error) {
            alert("Error adding document: ", error);
          });
        history.push("/adventures");
      }
      )
    },
    [history]
  );

  return (
    <React.Fragment>
      <form onSubmit={handlePost}>
        <Typography variant="h6" gutterBottom>
          Details
      </Typography>

        <Grid container spacing={3}>
          {/* for 2 inputs in 1 row
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="fname"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="lname"
          />
        </Grid> */}
          <Grid item xs={12}>
            <TextField
              required
              id="title"
              name="title"
              label="Tour Title"
              fullWidth
              helperText="E.g Everest Base Camp Hike"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="location"
              name="location"
              label="Location"
              fullWidth
              helperText="E.g Singapore, Singapore (City, Country)"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="operator"
              name="operator"
              label="Operator"
              fullWidth
              helperText="E.g Gobukit"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              id="price"
              name="price"
              label="Price"
              fullWidth
              helperText="E.g 500SGD"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              id="duration"
              name="duration"
              label="Duration"
              helperText="E.g 5D4N" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              id="type"
              name="type"
              select
              label="Type"
              value={type}
              onChange={handleType}
              helperText="Type of adventure">
              {types.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              required
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="dateFrom"
              label="Date From"
              minDate= {new Date()}
              maxDate= {selectedDateTo === null ?
                undefined : 
                selectedDateTo}
              value={selectedDateFrom}
              onChange={handleDateChangeFrom}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              required
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="dateTo"
              label="Date To"
              minDate= {selectedDateFrom === null ?
                new Date() : 
                selectedDateFrom}
              value={selectedDateTo}
              onChange={handleDateChangeTo}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="image"
              name="image"
              label="Image URL"
              fullWidth
              helperText="Must be image hosted on a URL already"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="pickup"
              name="pickup"
              label="Pick Up Address"
              fullWidth
              helperText="E.g 505 Beach Rd, Singapore 199583"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="dropoff"
              name="dropoff"
              label="Drop Off Address"
              fullWidth
              helperText="E.g 505 Beach Rd, Singapore 199583"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="details"
              name="details"
              label="Details"
              fullWidth
              multiline
              rowsMax="6"
              helperText="Press enter to write a new paragraph"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="itinerary"
              name="itinerary"
              label="Itinerary"
              fullWidth
              multiline
              rowsMax="6"
              helperText="Press enter to write seperate each day E.g Day 1 - "
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="included"
              name="included"
              label="Included"
              fullWidth
              multiline
              rowsMax="4"
              helperText="Press enter to write seperate each item"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="excluded"
              name="excluded"
              label="Excluded"
              fullWidth
              multiline
              rowsMax="4"
              helperText="Press enter to write seperate each item"
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="Zip / Postal code"
            fullWidth
            autoComplete="billing postal-code"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="country"
            name="country"
            label="Country"
            fullWidth
            autoComplete="billing country"
          />
        </Grid> */}
          {/* <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveAddress" value="yes" />}
            label="Use this address for payment details"
          />
        </Grid> */}
        </Grid>
        <React.Fragment>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
            >
              POST
                  </Button>
          </div>
        </React.Fragment>
      </form>
    </React.Fragment>
  );
}

export default withRouter(VendorForm);