import React from 'react';
import {Typography, Button, CssBaseline} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
// import firestore from '../config/Firestore';

import { Link } from 'react-router-dom';

// Import components
import Header from './Header';
import Footer from './Footer';


const useStyles = makeStyles(theme => ({
    button: {
      boxShadow: theme.shadows[4],
      width: "100%",
      marginTop: theme.spacing(4),
    },
    link: {
        margin: theme.spacing(1, 1.5),
        textDecoration: "none"
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
        height: "86vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
  }));

export default function Home() {
    const classes = useStyles();
    return (
        <React.Fragment>
            <CssBaseline />
            <Header classes={classes}/>
            <main>
                {/* Hero unit */}
                <div className={classes.heroContent}>
                    <Container maxWidth="sm">
                        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                            gobukit.
                  </Typography>
                        <Typography variant="h5" align="center" color="textSecondary" paragraph>
                            Your adventure begins here!
                  </Typography>
                        <Link to={'/adventures'} className={classes.link}>
                            <Button className={classes.button} variant="outlined" size="large" color="primary">
                                <b>Search for Adventures</b>
                            </Button>
                        </Link>
                    </Container>
                </div>
            </main>
            <Footer/>
        </React.Fragment>
    );
}