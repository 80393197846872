import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Home from './containers/Home';
import Adventures from './containers/Adventures';
import Adventure from './containers/Adventure/Adventure'
import LogIn from './containers/Login';
import Post from './containers/AddTourForm/Post';
import {AuthProvider} from './helpers/Auth';
import PrivateRoute from './helpers/PrivateRoute';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import { createMuiTheme, ThemeProvider} from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ff4400',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <AuthProvider>
    <BrowserRouter>
    <div>
      <Switch>
        <PrivateRoute path="/vendorForm" component={Post} />
        <Route path="/" exact component={Home} />
        <Route path="/adventures" exact component={Adventures} />
        <Route path="/adventures/:id" exact component={Adventure} />
        <Route path="/login" exact component={LogIn} />
        <Redirect from="/redirect" to="/adventures" />
        <Redirect from="*" to="/" />
      </Switch>
    </div>
    </BrowserRouter>
    </AuthProvider>
    </MuiPickersUtilsProvider>
    </ThemeProvider>

  );
}

export default App;
