import React from "react";
import PropTypes from "prop-types";
import { Typography, Card, Box, makeStyles } from "@material-ui/core";

import Emoji from '../../helpers/Emoji';

const useStyles = makeStyles(theme => ({
  card: {
    boxShadow: theme.shadows[4],
    marginBottom: theme.spacing(1)
  },
  noDecoration: {
    textDecoration: "none !important"
  },
  title: {
    transition: theme.transitions.create(["background-color"], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut
    }),
    cursor: "pointer",
    color: theme.palette.secondary.main,
  },
  link: {
    transition: theme.transitions.create(["background-color"], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut
    }),
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.dark
    }
  },
  showFocus: {
    "&:focus span": {
      color: theme.palette.secondary.dark
    }
  }
}));

export default function SideCard(props) {
  const classes = useStyles();
  const { title, details, emoji } = props;
  return (
    <Card className={classes.card}>
      <Box p={2}>
        <Typography variant="h6">
          <Emoji symbol={emoji} label={title} />
          <span>&nbsp;</span>
          <span><b>{title}</b></span>
        </Typography>
        <Typography variant="body1">
          {details.split('\n').map(function (item, key) {
            if(item === ""){
              return null
            }
            return (
              <span key={key}>
                - {item}
                <br />
              </span>
            )
          })}
        </Typography>
      </Box>
    </Card >
  );
}

SideCard.propTypes = {
  title: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  emoji: PropTypes.string.isRequired
};
