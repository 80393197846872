import React, { useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

import { Grid, Typography, Card, Box, Button, makeStyles } from "@material-ui/core";

import firebase from '../../config/Firebase';

// Components
import Header from '../Header';
import Footer from '../Footer';
import SideCard from './SideCard';
import SideDetails from './SideDetails';


const useStyles = makeStyles(theme => ({
  blogContentWrapper: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    maxWidth: 1280,
    width: "100%",
  },
  wrapper: {
    minHeight: "60vh"
  },
  img: {
    width: "100%",
    height: "auto"
  },
  card: {
    boxShadow: theme.shadows[4],
    marginBottom: theme.spacing(4)
  },
  sidebar: {
    position: "-webkit-sticky",
    position: "sticky",
    top: 0
  },
  button: {
    boxShadow: theme.shadows[4],
    width: "100%"
  }
}));

export default function Adventure(props) {
  const classes = useStyles();


  const [adventureInfo, setAdventure] = useState(null);

  const { id } = props.match.params

  useEffect(() => {
    listenForAdventureInfo();
  }, []);

  const listenForAdventureInfo = () => {
    firebase.firestore().collection('adventure')
      .doc(id).get().then(function (doc) {
        if (doc.exists) {
          // console.log("Document data:", doc.data());
          console.log("Document data:", doc.data().itinerary);
          doc.data().itinerary.replace("//", '</br>');
          setAdventure(doc.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          props.history.replace('/redirect');
        }
      }).catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  // console.log(adventureInfo);

  if (adventureInfo == null) {
    return (
      <div>
        Loading...
    </div>
    )
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Header />
      <Box
        className={classes.wrapper}
        display="flex"
        justifyContent="center"
      >
        <div className={classes.blogContentWrapper}>
          <Grid container spacing={5}>
            <Grid item md={9}>
              <Card className={classes.card}>
                <Box pt={3} pr={3} pl={3} pb={2}>
                  <Typography variant="h4">
                    <b>{adventureInfo.name}</b>
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {adventureInfo.operator}
                  </Typography>
                </Box>
                <img className={classes.img} src={adventureInfo.image} alt={adventureInfo.title} />
                <Box p={3}>
                  <Typography variant="h5" paragraph>
                    <b>Details</b>
                  </Typography>
                  <Typography paragraph>
                    {adventureInfo.details.split('\n').map(function (item, key) {
                      return (
                        <span key={key}>
                          {item}
                          <br />
                        </span>
                      )
                    })}
                  </Typography>
                  <Typography variant="h5" paragraph>
                    <b>Itinerary</b>
                  </Typography>
                  <Typography paragraph>
                    {adventureInfo.itinerary.split('\n').map(function (item, key) {
                      return (
                        <span key={key}>
                          {item}
                          <br />
                        </span>
                      )
                    })}
                  </Typography>
                </Box>
              </Card>
              <Box pt={2}>
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <SideCard title="Included" details={adventureInfo.included} emoji="✔️" />
                  </Grid>
                  <Grid item sm={6}>
                    <SideCard title="Excluded" details={adventureInfo.excluded} emoji="❌" />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className={classes.sidebar}>
                <Card className={classes.card}>
                  <SideDetails title="Pick Up Address" details={adventureInfo.pickup} emoji="📍" />
                  <SideDetails title="Drop Off Address" details={adventureInfo.dropoff} emoji="📍" />
                  <SideDetails title="Duration" details={adventureInfo.duration} emoji="⌚" />
                  <SideDetails title="Dates" details={adventureInfo.dates} emoji="📅" />
                  <SideDetails title="Price" details={adventureInfo.price} emoji="💰" />
                </Card>
                <Button className={classes.button} variant="outlined" size="large" color="primary" href={adventureInfo.link}>
                  <b>BUY TOUR</b>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Box>
      <Footer />
    </React.Fragment>
  );
}
