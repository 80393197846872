import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyDvE13RIy6f_d84xl7pDvZu_edY-PGLpio",
    authDomain: "bukit-75535.firebaseapp.com",
    databaseURL: "https://bukit-75535.firebaseio.com",
    projectId: "bukit-75535",
    storageBucket: "bukit-75535.appspot.com",
    messagingSenderId: "135635859333",
    appId: "1:135635859333:web:f7eb6e33ba3db4bfa336ec",
    measurementId: "G-R4PV5WY0XY"
  };
firebase.initializeApp(config);

export default firebase;